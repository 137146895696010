export default {
  FRONTEND_ROUTE: [
    'taskIndex',

    'changePassword',

    // Intakes routes
    'intakeIndex',
    'intakeNew',
    'intakeSessionNew',
    'intakeSessionShow',
    'intakeShow',

    // Cases routes
    'caseIndex',
    'caseSessionNew',
    'caseSessionShow',
    'caseTypeChangeNew',
    'caseTransferNew',
    'caseClosureNew',
    'caseReopenNew',
    'caseFeedbackNew',
    'caseFeedbackShow',
    'caseRequestShow',
    'caseShow',

    // Events routes
    'eventSessionNew',
    'eventIndex',
    'eventNew',
    'eventSessionShow',
    'eventMassImportNew',
    'eventMassImportShow',
    'eventShow',

    // Supervision routes
    'supervisionSessionNew',
    'supervisionSupervisorIndex',
    'supervisionSuperviseeIndex',
    'supervisionSessionClinicalLogNew',
    'supervisionSessionClinicalLogShow',
    'supervisionSessionCaseReviewNew',
    'supervisionSessionCaseReviewShow',
    'supervisionSessionShow',

    // Admin routes
    'clientIndex',
    'clientNew',
    'clientShow',
    'staffIndex',
    'staffNew',
    'staffShow',
    'venueIndex',
    'centreIndex',
    'centreNew',
    'centreShow',
    'supervisionIndex',
    'supervisionShow',
    'displaySettingsIndex',
    'emailSettingIndex',
    'organisationsIndex',
    'masquerade',

    // Report routes
    'weeklySessionReport',
    'caseSummaryReport',
    'closeCaseReport',
    'individualCounsellorReport',
    'supervisionSessionReport',
    'counsellorWorkloadReport',
    'caseMissingPrimaryProblemReporty',
    'clientSpecialReport',
    'dailyAppointmentReport',
    'caseSpecialReport',
    'intakeSpecialReport',
    'ccSummaryReport',
    'seSummaryReport',
    'svSummaryReport',
    'individualCcCaseworkerReport',
    'individualSESVCaseworkerReport',
    'individualPathsCaseworkerReport',
    'individualCciCaseworkerReport',
    'individualProgrammesPerformanceReport',

    // Calendar routes
    'calendarEventIndex',
    'calendarEventNew',
    'calendarEventShow',

    'logIndex',
    'publicActivityIndex',
  ],
};
