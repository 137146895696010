export default {
  TABLE: {
    ATTACHMENT: {
      COLUMN: ['asbDate', 'asbFileName', 'remarks', 'createdBy', 'actions'],
    },
    CASE: {
      INITIAL_SORT_KEY: 'cs_cases.next_review_date',
      INITIAL_SORT_ORDER: 'asc',
      COLUMN: [
        'caseRefNo',
        'clientName',
        'ljsfoclIcNumber',
        'mainCaseWorker',
        'csDateOpened',
        'csDateClosed',
        'classification',
        'dateOfNextReview',
        'status',
      ],
      FILTERS: ['caseWorkers', 'caseStates', 'caseClassificationStatus'],
      TEMPORAL_PERMISSIONS: {
        TITLE: 'Case Workers',
        DELETE_MESSAGE_LABEL: 'Case Worker Record',
        COLUMN: ['name', 'startDate', 'endDate', 'status', 'actions'],
        DATA: ['staffName', 'startDate', 'endDate', 'activeStatusTag', 'actions'],
      },
    },
    CASE_ACTIVITY: {
      COLUMN: [
        'date',
        'name',
        'visitorType',
        'status',
        'classification',
        'frequencyOfVisit',
        'duration',
        'createdBy',
        'actions',
      ],
    },
    CLIENT: {
      COLUMN: ['name', 'ic', 'dateOfBirth', 'address', 'nextOfKins', 'contact', 'mainCaseWorker'],
    },
    INTAKE: {
      INITIAL_SORT_KEY: 'LFB_lions_befriendees_incoming_referrals.referral_date',
    },
    CASE_HISTORY: {
      COLUMN: ['displayId', 'startDate', 'closeDate']
    },
    TASK: {
      COLUMN: ['item', 'status', 'date', 'dueDate', 'misc', 'actions'],
    },
    NEXT_OF_KIN: {
      COLUMN: ['primary', 'name', 'relationship', 'contact', 'email', 'remarks'],
    },
    REFERRALS: {
      TITLE: 'Referral Information',
      COLUMN: [
        'referralSource',
        'personUnsortable',
        'organisationUnsortable',
        'designation',
        'contact',
        'fax',
        'email',
        'remarksUnsortable',
      ],
    },
    OUTCOME_ACTIVITIES: {
      COLUMN: ['date', 'request', 'remarks', 'decision'],
    },
    SUPERVISION: {
      COLUMN: ['csStartDateAndTime', 'supervisors', 'supervisees', 'actions'],
    },
  },
};
