import { AxiosResponse } from 'axios';
/**
 * Accepts AxiosResponse object encapsulating a file/blob
 * Returns an object of the form {contentType, filename}
 */
export function getFileDetails(response: AxiosResponse) {
  const contentType = response.headers['content-type'];
  const filename = response.headers['content-disposition'].match(/filename="(.+)"/)[1];
  return { contentType, filename };
}

/**
 * Downloads the blob encapsulated in response and sets filename according to specified headers
 */
export function downloadBlob(response: AxiosResponse) {
  const { contentType, filename } = getFileDetails(response);
  const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(link.href); // release blob from memory
}
