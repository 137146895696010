import {
  StaffPostData,
  MasqueradeData,
  StaffNewData,
  ClientPostData,
  ClientNewData,
  ClientHistoryPostData,
  CallerPostData,
  CallerNewData,
  CvwoVenuePostData,
  SupervisionRelationshipPostData,
  CaseTypePostData,
  CaseCategoryPostData,
  CentrePostData,
  CentreNewData,
  CvwoOrganisationPostData,
  DisplaySettingsData,
  EmailSettingPostData,
} from 'types/admin';
import {
  AttachmentPostData,
  CaseAttachmentPostData,
  ClientAttachmentPostData,
  CaseRequestAttachmentPostData,
  CaseReviewAttachmentPostData,
  CaseSessionAttachmentPostData,
  IntakeAttachmentPostData,
} from 'types/attachments';
import { ChangePasswordPostData } from 'types/auth';
import {
  TemporalPermissionPostData,
  TemporalPermissionEditData,
  CaseCanCarerPostData,
  CaseFeedbackPostData,
  CaseFeedbackNewPostData,
  CasePostData,
  CaseSessionPostData,
  CaseRequestTypeChangeNewData,
  CaseRequestCategoryChangeNewData,
  CaseRequestTransferNewData,
  CanCarerVisitAdditionalDetail,
  CaseReviewPostData,
  CaseRequestTransferPostData,
  CaseSessionAction,
  CaseRequestTransferAction,
  CaseRequestTypeChangePostData,
  CaseRequestCategoryChangePostData,
  CaseRequestSuspensionNewData,
  CaseRequestTypeChangeAction,
  CaseRequestCategoryChangeAction,
  CaseSessionNewData,
  CaseRequestSuspensionAction,
  CaseReviewNewData,
  CaseRequestSuspensionPostData,
  CaseRequestClosureNewData,
  CaseRequestClosureAction,
  CaseRequestClosurePostData,
  CaseRequestReopenNewData,
  CaseRequestReopenAction,
  CaseRequestReopenPostData,
  CaseSessionConcernsPostData,
} from 'types/cases';
import {
  SupervisionSessionPostData,
  SupervisionSessionCaseReviewNewData,
  SupervisionSessionCaseReviewAction,
  SupervisionSessionCaseReviewPostData,
  SupervisionSessionClinicalLogNewData,
  SupervisionSessionClinicalLogPostData,
} from 'types/supervision';
import { CalendarEventNewData, CalendarEventPatchData } from 'types/calendar_events';
import { DateRange } from 'components/forms/fields/DateRangeField';
import {
  EventPostData,
  EventNewData,
  EventStatusAction,
  EventSessionPostData,
  EventSessionAction,
  EventSessionNewData,
  EventMassImportDataTypes,
  EventMassImportCheckResult,
} from 'types/events';
import {
  IntakeActionsPostData,
  IntakeAssessmentPostData,
  IntakeOutcomeApprovalPostData,
  IntakeOutcomeNewData,
  IntakePatchData,
  IntakePostData,
  IntakePromoteDecisionPostData,
  IntakeSessionAction,
  IntakeSessionNewData,
  IntakeSessionPostData,
} from 'types/intakes';

export default {
  ADMIN: {
    BASE: 'admin',
    GET_PEOPLE_URL: {
      ROUTE: 'people',
    },
    GET_STAFF_URL: {
      ROUTE: 'staff',
    },
    GET_CLIENT_URL: {
      ROUTE: 'clients',
    },
    GET_CALLER_URL: {
      ROUTE: 'callers',
    },
    GET_CAN_CARER_URL: {
      ROUTE: 'can_carers',
    },
    GET_VENUE_URL: {
      ROUTE: 'venues',
    },
    GET_CENTRE_URL: {
      ROUTE: 'centres',
    },
    GET_ORGANISATION_URL: {
      ROUTE: 'organisations',
    },
    GET_SUPERVISION_RELATIONSHIP_URL: {
      ROUTE: 'supervision_relationships',
    },
    GET_CASE_TYPE_URL: {
      ROUTE: 'case_types',
    },
    GET_CASE_CATEGORY_URL: {
      ROUTE: 'case_categories',
    },
    GET_DISPLAY_SETTINGS_URL: {
      ROUTE: 'display_settings',
    },
    GET_EMAIL_SETTING_URL: {
      ROUTE: 'email_settings',
    },
    GET_STAFF_LIST: {},
    GET_STAFF_LIST_PAGINATED: {
      ROUTE: 'paginated',
    },
    GET_STAFF: {
      DYNAMIC_ROUTE: (actableId: number) => actableId,
    },
    PATCH_STAFF: {
      DYNAMIC_ROUTE: (actableId: number) => actableId,
      BODY: (data: StaffPostData) => data,
    },
    POST_STAFF: {
      BODY: (data: StaffNewData) => data,
    },
    DELETE_STAFF: {
      DYNAMIC_ROUTE: (staffId: number) => staffId,
    },
    TOGGLE_HIDDEN_STAFF: {
      DYNAMIC_ROUTE: (actableId: number) => `${actableId}/toggle_hidden`,
    },
    GET_CLIENT_LIST: {},
    GET_CLIENT: {
      DYNAMIC_ROUTE: (actableId: number) => actableId,
    },
    PATCH_CLIENT: {
      DYNAMIC_ROUTE: (actableId: number) => actableId,
      BODY: (data: ClientPostData) => data,
    },
    POST_CLIENT: {
      BODY: (data: ClientNewData) => data,
    },
    DELETE_CLIENT: {
      DYNAMIC_ROUTE: (clientId: number) => clientId,
    },
    GET_CLIENT_ATTACHMENT_LIST: {
      DYNAMIC_ROUTE: (actableId: number) => `${actableId}/attachments`,
    },
    ADD_CLIENT_ATTACHMENT_LIST: {
      DYNAMIC_ROUTE: (actableId: number) => `${actableId}/attachments`,
      BODY: (attachmentData: ClientAttachmentPostData) => attachmentData,
    },
    EDIT_CLIENT_ATTACHMENT: {
      DYNAMIC_ROUTE: (actableId: number) => `${actableId}/attachments`,
      BODY: (attachmentData: AttachmentPostData) => attachmentData,
    },
    GET_CAN_CARER_LIST_PAGINATED: {
      ROUTE: 'paginated',
    },
    POST_CLIENT_HISTORY: {
      DYNAMIC_ROUTE: (actableId: number) => `${actableId}/histories`,
      BODY: (data: ClientHistoryPostData) => data,
    },
    GET_CALLER: {
      DYNAMIC_ROUTE: (actableId: number) => actableId,
    },
    PATCH_CALLER: {
      DYNAMIC_ROUTE: (actableId: number) => actableId,
      BODY: (data: CallerPostData) => data,
    },
    POST_CALLER: {
      BODY: (data: CallerNewData) => data,
    },
    GET_VENUE_LIST: {},
    SEARCH_EVENT_CLIENT_LIST: {
      DYNAMIC_ROUTE: (query: string) => `find/${query}`,
    },
    SEARCH_EVENT_STAFF_LIST: {
      DYNAMIC_ROUTE: (query: string) => `find/${query}`,
    },
    SEARCH_STAFF_LIST: {
      DYNAMIC_ROUTE: (query: string) => `find/${query}`,
    },
    SEARCH_CLIENT_LIST: {
      DYNAMIC_ROUTE: (query: string) => `find/${query}`,
    },
    SEARCH_CALLER_LIST: {
      DYNAMIC_ROUTE: (query: string) => `find/${query}`,
    },
    SEARCH_CAN_CARER_LIST: {
      DYNAMIC_ROUTE: (query: string) => `find/${query}`,
    },
    FIND_PEOPLE_EXACT_NAME: {
      DYNAMIC_ROUTE: (query: string) => `find_name_exact/${query}`,
    },
    FIND_PEOPLE_EXACT_IC: {
      DYNAMIC_ROUTE: (query: string) => `find_ic_number_exact/${query}`,
    },
    FIND_STAFF_EXACT_USERNAME: {
      DYNAMIC_ROUTE: (query: string) => `find_username_exact/${query}`,
    },
    FIND_STAFF_EXACT_EMAIL: {
      DYNAMIC_ROUTE: (query: string) => `find_email_exact/${query}`,
    },
    SEARCH_ORGANISATION_LIST: {
      DYNAMIC_ROUTE: (query: string) => `find/${query}`,
    },
    ADD_CVWO_VENUE: {
      BODY: (venue: CvwoVenuePostData) => venue,
    },
    GET_SUPERVISION_RELATIONSHIP_LIST: {},
    ADD_SUPERVISION_RELATIONSHIP: {
      BODY: (data: SupervisionRelationshipPostData) => data,
    },
    GET_SUPERVISION_RELATIONSHIP_BY_STAFF: {
      DYNAMIC_ROUTE: (staffId: number) => `${staffId}/supervision_relationships`,
    },
    DELETE_SUPERVISION_RELATIONSHIP: {
      DYNAMIC_ROUTE: (id: number) => id,
    },
    ADD_CASE_TYPE: {
      BODY: (case_type: CaseTypePostData) => case_type,
    },
    GET_CASE_TYPE_LIST: {},
    PATCH_CASE_TYPE: {
      DYNAMIC_ROUTE: (id: number) => id,
      BODY: (data: CaseTypePostData) => data,
    },
    DELETE_CASE_TYPE: {
      DYNAMIC_ROUTE: (id: number) => id,
    },
    ADD_CASE_CATEGORY: {
      BODY: (case_category: CaseCategoryPostData) => case_category,
    },
    GET_CASE_CATEGORY_LIST: {},
    PATCH_CASE_CATEGORY: {
      DYNAMIC_ROUTE: (id: number) => id,
      BODY: (data: CaseCategoryPostData) => data,
    },
    DELETE_CASE_CATEGORY: {
      DYNAMIC_ROUTE: (id: number) => id,
    },
    GET_CENTRE_LIST: {},
    GET_CENTRE_LIST_PAGINATED: {
      ROUTE: 'paginated',
    },
    GET_CENTRE: {
      DYNAMIC_ROUTE: (centreId: number | undefined) => centreId,
    },
    PATCH_CENTRE: {
      DYNAMIC_ROUTE: (centreId: number | undefined) => centreId,
      BODY: (data: CentrePostData) => data,
    },
    POST_CENTRE: {
      BODY: (data: CentreNewData) => data,
    },
    GET_ORGANISATION_LIST: {},
    ADD_CVWO_ORGANISATION: {
      BODY: (org: CvwoOrganisationPostData) => org,
    },
    PATCH_CVWO_ORGANISATION: {
      DYNAMIC_ROUTE: (id: number) => id,
      BODY: (org: CvwoOrganisationPostData) => org,
    },
    GET_DISPLAY_SETTINGS: {},
    PATCH_DISPLAY_SETTINGS: {
      DYNAMIC_ROUTE: (data: DisplaySettingsData) => data.id,
      BODY: (data: DisplaySettingsData) => data,
    },
    GET_EMAIL_SETTING: {},
    UPDATE_EMAIL_SETTING: {
      DYNAMIC_ROUTE: (data: EmailSettingPostData) => data.id,
      BODY: (data: EmailSettingPostData) => data,
    },
  },
  ATTACHMENTS: {
    BASE: 'attachments',
    CREATE_ATTACHMENT: {
      BODY: (formData: FormData) => formData,
    },
    DELETE_ATTACHMENT: {
      DYNAMIC_ROUTE: (id: number) => id,
    },
  },
  AUTH: {
    BASE: 'users',
    SIGN_IN: {
      ROUTE: 'sign_in',
      BODY: (login: string, password: string, rememberMe: boolean) => {
        return {
          user: {
            username: login,
            password: password,
            remember_me: rememberMe,
          },
        };
      },
    },
    TWO_FACTOR: {
      BASE: 'two_factor',
      // TOTP
      GET_TOTP: {
        ROUTE: 'totp_requests',
      },
      SUBMIT_TOTP: {
        ROUTE: 'totp_requests',
        BODY: (otpAttempt: string) => {
          return {
            two_factor_otp_request: {
              otp: otpAttempt,
            },
          };
        },
      },
      GENERATE_TOTP: {
        ROUTE: 'totp_requests/generate',
      },
      // EMAIL OTP
      SEND_OTP: {
        ROUTE: 'email_requests/send',
      },
      RESEND_OTP: {
        ROUTE: 'email_requests/resend',
      },
      SUBMIT_OTP: {
        ROUTE: 'email_requests',
        BODY: (otpAttempt: string) => {
          return {
            two_factor_otp_request: {
              otp: otpAttempt,
            },
          };
        },
      },
    },
    SGPASS: {
      BASE: 'sgpass',
      INIT_SGPASS_AUTH: {
        ROUTE: 'create',
      },
      GET_SGPASS_AUTH_STATUS: {
        ROUTE: 'status',
        BODY: (code: string | null, state: string | null, redirectUri: string) => {
          return {
            code,
            state,
            redirect_uri: redirectUri,
          };
        },
      },
    },
    SIGN_OUT: {
      ROUTE: 'sign_out',
    },
    FORGET_PASSWORD: {
      ROUTE: 'password',
      BODY: (email: string, redirectUrl: string) => {
        return {
          user: {
            email: email,
            redirect_url: redirectUrl,
          },
        };
      },
    },
    RESET_PASSWORD: {
      ROUTE: 'password',
      BODY: (token: string, password: string, passwordConfirmation: string) => {
        return {
          user: {
            reset_password_token: token,
            password: password,
            password_confirmation: passwordConfirmation,
          },
        };
      },
    },
    GET_CHANGE_PASSWORD_TOKEN: {
      ROUTE: 'edit',
    },
    CHANGE_PASSWORD: {
      DYNAMIC_ROUTE: (userId: number) => userId,
      BODY: (formValues: ChangePasswordPostData) => {
        return {
          user: formValues,
        };
      },
    },
    MASQUERADE_AS: {
      ROUTE: 'masquerade',
      BODY: (data: MasqueradeData['masquerade_as_id']) => data,
    },
    GET_CURRENT_USER: {
      ROUTE: 'current_user',
    },
    UNMASQUERADE: {
      ROUTE: 'masquerade/back',
    },
  },
  CALENDAR_EVENTS: {
    BASE: 'calendar_events',
    GET_CALENDAR_EVENT_LIST: {
      ROUTE: 'find',
    },
    GET_CALENDAR_EVENT: {
      DYNAMIC_ROUTE: (id: number) => id,
    },
    ADD_CALENDAR_EVENT: {
      BODY: (calendarEvent: CalendarEventNewData) => calendarEvent,
    },
    UPDATE_CALENDAR_EVENT: {
      DYNAMIC_ROUTE: (calendarEvent: CalendarEventPatchData) => calendarEvent.id,
      BODY: (calendarEvent: CalendarEventPatchData) => calendarEvent,
    },
    DELETE_CALENDAR_EVENT: {
      DYNAMIC_ROUTE: (id: number) => id,
    },
  },
  CASES: {
    BASE: 'cases',
    GET_CASE_LIST: {},
    GET_CASE: {
      DYNAMIC_ROUTE: (id: number) => id,
    },
    SEARCH_CASE_LIST: {
      DYNAMIC_ROUTE: (query: string) => `find/${query}`,
    },
    GET_CASE_SESSION_LIST: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/sessions`,
    },
    GET_CASE_SESSION: {
      DYNAMIC_ROUTE: (caseId: number, sessionId: number) => `${caseId}/sessions/${sessionId}`,
    },
    GET_CASE_REVIEW_LIST: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/reviews`,
    },
    GET_CASE_REVIEW: {
      DYNAMIC_ROUTE: (caseId: number, reviewId: number) => `${caseId}/reviews/${reviewId}`,
    },
    GET_CASE_ATTACHMENT_LIST: {
      DYNAMIC_ROUTE: (id: number) => `${id}/attachments`,
    },
    ADD_CASE_ATTACHMENT_LIST: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/attachments`,
      BODY: (attachmentData: CaseAttachmentPostData) => attachmentData,
    },
    EDIT_CASE_ATTACHMENT: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/attachments`,
      BODY: (attachmentData: AttachmentPostData) => attachmentData,
    },
    GET_CASE_TEMPORAL_PERMISSION_LIST: {
      DYNAMIC_ROUTE: (id: number) => `${id}/temporal_permissions`,
    },
    ADD_TEMPORAL_PERMISSION: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/temporal_permissions`,
      BODY: (temporalPermission: TemporalPermissionPostData) => temporalPermission,
    },
    PATCH_TEMPORAL_PERMISSION: {
      DYNAMIC_ROUTE: (caseId: number, permissionId: number) => `${caseId}/temporal_permissions/${permissionId}`,
      BODY: (temporalPermission: TemporalPermissionEditData) => temporalPermission,
    },
    DELETE_TEMPORAL_PERMISSION: {
      DYNAMIC_ROUTE: (caseId: number, payloadId: number) => `${caseId}/temporal_permissions/${payloadId}`,
    },
    GET_CASE_CAN_CARER_LIST: {
      DYNAMIC_ROUTE: (id: number) => `${id}/can_carers`,
    },
    ADD_CASE_CAN_CARER: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/can_carers`,
      BODY: (canCarer: CaseCanCarerPostData) => canCarer,
    },
    PATCH_CASE_CAN_CARER: {
      DYNAMIC_ROUTE: (caseId: number, canCarerId: number) => `${caseId}/can_carers/${canCarerId}`,
      BODY: (canCarer: CaseCanCarerPostData) => canCarer,
    },
    DELETE_CASE_CAN_CARER: {
      DYNAMIC_ROUTE: (caseId: number, payloadId: number) => `${caseId}/can_carers/${payloadId}`,
    },
    GET_CASE_REQUESTS_LIST: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/requests`,
    },
    GET_CASE_REVIEW_LIST_BY_CASE: {
      // This is actually case discussions on Lion's backend
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/case_reviews`,
    },
    GET_CLINICAL_LOG_LIST_BY_CASE: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/clinical_logs`,
    },
    GET_CASE_REQUEST: {
      DYNAMIC_ROUTE: (caseId: number, requestId: number) => `${caseId}/requests/${requestId}`,
    },
    PATCH_CASE: {
      DYNAMIC_ROUTE: (data: CasePostData) => data.case_case.id,
      BODY: (data: CasePostData) => data,
    },
    PATCH_CASE_SESSION: {
      DYNAMIC_ROUTE: (caseId: number, data: CaseSessionPostData) => `${caseId}/sessions/${data.case_session.id}`,
      BODY: (data: CaseSessionPostData) => data,
    },
    PATCH_CAN_CARER_VISIT_DETAILS: {
      DYNAMIC_ROUTE: (caseId: number, caseVisitId: number) =>
        `${caseId}/sessions/${caseVisitId}/can_carer_visit_details`,
      BODY: (data: CanCarerVisitAdditionalDetail) => data,
    },
    PATCH_CASE_REVIEW: {
      DYNAMIC_ROUTE: (caseId: number, data: CaseReviewPostData) => `${caseId}/reviews/${data.case_review.id}`,
      BODY: (data: CaseReviewPostData) => data,
    },
    PATCH_CASE_SESSION_STATE: {
      DYNAMIC_ROUTE: (caseId: number, actableId: number, action: CaseSessionAction) =>
        `${caseId}/sessions/${actableId}/${action}`,
      BODY: (data?: CaseSessionPostData) => (data ? data : {}),
    },
    DELETE_CASE_SESSION: {
      DYNAMIC_ROUTE: (caseId: number, payloadId: number) => `${caseId}/sessions/${payloadId}`,
    },
    DELETE_REVIEW_SESSION: {
      DYNAMIC_ROUTE: (caseId: number, payloadId: number) => `${caseId}/reviews/${payloadId}`,
    },
    PATCH_CASE_REQUEST_TYPE_CHANGE: {
      DYNAMIC_ROUTE: (caseId: number, data: CaseRequestTypeChangePostData) =>
        `${caseId}/type_changes/${data.case_type_change.id}`,
      BODY: (data: CaseRequestTypeChangePostData) => data.case_type_change,
    },
    PATCH_CASE_REQUEST_CATEGORY_CHANGE: {
      DYNAMIC_ROUTE: (caseId: number, data: CaseRequestCategoryChangePostData) =>
        `${caseId}/category_changes/${data.case_category_change.id}`,
      BODY: (data: CaseRequestCategoryChangePostData) => data.case_category_change,
    },
    UPDATE_CASE_SESSION: {
      DYNAMIC_ROUTE: (caseId: number, actableId: number, state: string) => `${caseId}/sessions/${actableId}/${state}`,
    },
    UPDATE_REVIEW_SESSION: {
      DYNAMIC_ROUTE: (caseId: number, actableId: number) => `${caseId}/reviews/${actableId}`,
    },
    PATCH_CASE_REQUEST_TYPE_CHANGE_STATE: {
      DYNAMIC_ROUTE: (caseId: number, requestId: number, action: CaseRequestTypeChangeAction) =>
        `${caseId}/type_changes/${requestId}/${action}`,
      BODY: (data?: CaseRequestTypeChangePostData) => (data ? data : {}),
    },
    PATCH_CASE_REQUEST_CATEGORY_CHANGE_STATE: {
      DYNAMIC_ROUTE: (caseId: number, requestId: number, action: CaseRequestCategoryChangeAction) =>
        `${caseId}/category_changes/${requestId}/${action}`,
      BODY: (data?: CaseRequestCategoryChangePostData) => (data ? data : {}),
    },
    POST_CASE_SESSION: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/sessions`,
      BODY: (data: CaseSessionNewData) => data,
    },
    POST_CASE_REVIEW: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/reviews`,
      BODY: (data: CaseReviewNewData) => data,
    },
    PATCH_CASE_REQUEST_CASE_DOCUMENTATION_SESSION: {
      DYNAMIC_ROUTE: (caseId: number, requestId: number) => `${caseId}/requests/${requestId}/add_documentation`,
      BODY: (data: CaseSessionNewData) => data,
    },
    PATCH_CASE_SESSION_CASE_DOCUMENTATION_SESSION: {
      DYNAMIC_ROUTE: (caseId: number, sessionId: number) => `${caseId}/sessions/${sessionId}/add_documentation`,
      BODY: (data: CaseSessionNewData) => data,
    },
    CASE_SESSION_ATTACHMENT: {
      DYNAMIC_ROUTE: (caseId: number, sessionId: number) => `${caseId}/sessions/${sessionId}/attachments`,
      BODY: (attachmentData: CaseSessionAttachmentPostData | AttachmentPostData) => attachmentData,
    },
    CASE_REVIEW_ATTACHMENT: {
      DYNAMIC_ROUTE: (caseId: number, reviewId: number) => `${caseId}/reviews/${reviewId}/attachments`,
      BODY: (attachmentData: CaseReviewAttachmentPostData | AttachmentPostData) => attachmentData,
    },
    CASE_REQUEST_ATTACHMENT: {
      DYNAMIC_ROUTE: (caseId: number, requestId: number) => `${caseId}/requests/${requestId}/attachments`,
      BODY: (attachmentData: CaseRequestAttachmentPostData | AttachmentPostData) => attachmentData,
    },
    CASE_REQUEST_TYPE_CHANGE: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/type_changes`,
      BODY: (data: CaseRequestTypeChangeNewData) => data,
    },
    CASE_REQUEST_CATEGORY_CHANGE: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/category_changes`,
      BODY: (data: CaseRequestCategoryChangeNewData) => data,
    },
    CASE_REQUEST_TRANSFER: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/transfers/new`,
    },
    POST_CASE_REQUEST_TRANSFER: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/transfers`,
      BODY: (data: CaseRequestTransferNewData) => data,
    },
    PATCH_CASE_REQUEST_TRANSFER: {
      DYNAMIC_ROUTE: (caseId: number, data: CaseRequestTransferPostData | CaseRequestTransferNewData) =>
        `${caseId}/transfers/${data.case_transfer.id}`,
      BODY: (data: CaseRequestTransferPostData | CaseRequestTransferNewData) => data,
    },
    CASE_REQUEST_TRANSFER_STATE: {
      DYNAMIC_ROUTE: (caseId: number, requestId: number, action: CaseRequestTransferAction) =>
        `${caseId}/transfers/${requestId}/${action}`,
      BODY: (data?: CaseRequestTransferPostData) => (data ? data : {}),
    },
    CASE_REQUEST_SUSPENSION: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/suspensions`,
      BODY: (data: CaseRequestSuspensionNewData) => data,
    },
    CASE_REQUEST_SUSPENSION_NEW: {
      DYNAMIC_ROUTE: (caseId: number, data: CaseRequestSuspensionNewData) =>
        `${caseId}/suspensions/${data.case_suspension.id}`,
      BODY: (data: CaseRequestSuspensionNewData) => data,
    },
    CASE_REQUEST_SUSPENSION_STATE: {
      DYNAMIC_ROUTE: (caseId: number, requestId: number, action: CaseRequestSuspensionAction) =>
        `${caseId}/suspensions/${requestId}/${action}`,
      BODY: (data?: CaseRequestSuspensionPostData) => (data ? data : {}),
    },
    PATCH_CASE_REQUEST_SUSPENSION: {
      DYNAMIC_ROUTE: (caseId: number, data: CaseRequestSuspensionPostData) =>
        `${caseId}/suspensions/${data.case_suspension.id}`,
      BODY: (data: CaseRequestSuspensionPostData) => data,
    },
    CASE_REQUEST_CLOSURE: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/closures`,
      BODY: (data: CaseRequestClosureNewData) => data,
    },
    CASE_REQUEST_CLOSURE_NEW: {
      DYNAMIC_ROUTE: (caseId: number, data: CaseRequestClosureNewData) => `${caseId}/closures/${data.case_closure.id}`,
      BODY: (data: CaseRequestClosureNewData) => data,
    },
    CASE_REQUEST_CLOSURE_STATE: {
      DYNAMIC_ROUTE: (caseId: number, requestId: number, action: CaseRequestClosureAction) =>
        `${caseId}/closures/${requestId}/${action}`,
      BODY: (data?: CaseRequestClosurePostData) => (data ? data : {}),
    },
    PATCH_CASE_REQUEST_CLOSURE: {
      DYNAMIC_ROUTE: (caseId: number, data: CaseRequestClosurePostData) => `${caseId}/closures/${data.case_closure.id}`,
      BODY: (data: CaseRequestClosurePostData) => data,
    },
    POST_CASE_REQUEST_REOPEN: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/reopens`,
      BODY: (data: CaseRequestReopenNewData) => data,
    },
    PATCH_CASE_REQUEST_REOPEN_NEW: {
      DYANMIC_ROUTE: (caseId: number, data: CaseRequestReopenNewData) => `${caseId}/reopens/${data.case_reopen.id}`,
      BODY: (data: CaseRequestReopenNewData) => data,
    },
    PATCH_CASE_REQUEST_REOPEN_STATE: {
      DYNAMIC_ROUTE: (caseId: number, requestId: number, action: CaseRequestReopenAction) =>
        `${caseId}/reopens/${requestId}/${action}`,
      BODY: (data?: CaseRequestReopenPostData) => (data ? data : {}),
    },
    PATCH_CASE_REQUEST_REOPEN: {
      DYNAMIC_ROUTE: (caseId: number, data: CaseRequestReopenPostData) => `${caseId}/reopens/${data.case_reopen.id}`,
      BODY: (data: CaseRequestReopenPostData) => data,
    },
    DELETE_CASE_CLOSURE: {
      DYNAMIC_ROUTE: (caseId: number, payloadId: number) => `${caseId}/closures/${payloadId}`,
    },
    UPDATE_CASE_AND_CLIENTS: {
      DYNAMIC_ROUTE: (caseId: number) => caseId,
      BODY: (combinedData: CaseSessionConcernsPostData) => ({ case_case: combinedData }),
    },
    GET_CASE_FEEDBACK: {
      DYNAMIC_ROUTE: (caseId: number, feedbackId: number) => `${caseId}/feedbacks/${feedbackId}`,
    },
    GET_CASE_FEEDBACKS_LIST: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/feedbacks`,
    },
    POST_NEW_CASE_FEEDBACK: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/feedbacks`,
      BODY: (data: CaseFeedbackNewPostData) => data,
    },
    PATCH_CASE_FEEDBACK: {
      DYNAMIC_ROUTE: (caseId: number, feedbackId: number) => `${caseId}/feedbacks/${feedbackId}`,
      BODY: (data: CaseFeedbackPostData) => data,
    },
    POST_NEW_CASES_GROUP: {
      ROUTE: 'cases_groups/new',
      BODY: (data: { case_ids: number[] }) => data,
    },
    PATCH_CASES_GROUP: {
      DYNAMIC_ROUTE: (casesGroupId: number) => `cases_groups/${casesGroupId}`,
      BODY: (data: { case_ids: number[] }) => data,
    },
    DELETE_CASES_GROUP: {
      DYNAMIC_ROUTE: (casesGroupId: number) => `cases_groups/${casesGroupId}`,
    },
    DELETE_CASES_GROUP_CASE: {
      DYNAMIC_ROUTE: (casesGroupId: number) => `cases_groups/${casesGroupId}/remove_case`,
      BODY: (caseId: number) => {
        return {
          case_id: caseId,
        };
      },
    },
    ADD_CASES_GROUP_CASE: {
      DYNAMIC_ROUTE: (casesGroupId: number) => `cases_groups/${casesGroupId}/add_case`,
      BODY: (caseId: number) => {
        return {
          case_id: caseId,
        };
      },
    },
  },
  CONSTANTS: {
    BASE: 'constants',
    GET_CONSTANTS: {},
  },
  EVENTS: {
    BASE: 'events',
    GET_EVENT_LIST: {},
    GET_EVENT: {
      DYNAMIC_ROUTE: (id: number) => id,
    },
    PATCH_EVENT: {
      DYNAMIC_ROUTE: (data: EventPostData) => data.event_event.id,
      BODY: (data: EventPostData) => data,
    },
    PATCH_EVENT_STATE: {
      DYNAMIC_ROUTE: (eventId: number, action: EventStatusAction) => `${eventId}/${action}`,
      BODY: (data?: EventPostData) => (data ? data : {}),
    },
    POST_EVENT: {
      BODY: (data: EventNewData) => data,
    },
    DELETE_EVENT: {
      DYNAMIC_ROUTE: (eventId: number) => eventId,
    },
    GET_EVENT_SESSION_LIST: {
      DYNAMIC_ROUTE: (eventId: number) => `${eventId}/sessions`,
    },
    GET_EVENT_SESSION: {
      DYNAMIC_ROUTE: (eventId: number, sessionId: number) => `${eventId}/sessions/${sessionId}`,
    },
    PATCH_EVENT_SESSION: {
      DYNAMIC_ROUTE: (eventId: number, data: EventSessionPostData) => `${eventId}/sessions/${data.event_session.id}`,
      BODY: (data: EventSessionPostData) => data,
    },
    PATCH_EVENT_SESSION_STATE: {
      DYNAMIC_ROUTE: (eventId: number, sessionId: number, action: EventSessionAction) =>
        `${eventId}/sessions/${sessionId}/${action}`,
      BODY: (data?: EventSessionPostData) => (data ? data : {}),
    },
    POST_EVENT_SESSION: {
      DYNAMIC_ROUTE: (eventId: number) => `${eventId}/sessions`,
      BODY: (data: EventSessionNewData) => data,
    },
    DELETE_EVENT_SESSION: {
      DYNAMIC_ROUTE: (eventId: number, sessionId: number) => `${eventId}/sessions/${sessionId}`,
    },
    GET_EVENT_CLIENT_LIST: {
      DYNAMIC_ROUTE: (id: number) => `${id}/client`,
    },
    ADD_EVENT_CLIENT: {
      DYNAMIC_ROUTE: (eventId: number) => `${eventId}/add_client`,
      BODY: (clientId: number, eventId: number) => {
        return {
          client_id: clientId,
          event_id: eventId,
        };
      },
    },
    DELETE_EVENT_CLIENT: {
      DYNAMIC_ROUTE: (eventId: number) => `${eventId}/remove_client`,
    },
    GET_EVENT_STAFF_LIST: {
      DYNAMIC_ROUTE: (id: number) => `${id}/staff`,
    },
    ADD_EVENT_STAFF: {
      DYNAMIC_ROUTE: (eventId: number) => `${eventId}/add_staff`,
      BODY: (staffId: number, eventId: number) => {
        return {
          staff_id: staffId,
          event_id: eventId,
        };
      },
    },
    DELETE_EVENT_STAFF: {
      DYNAMIC_ROUTE: (eventId: number) => `${eventId}/remove_staff`,
    },
    GET_EVENT_SUMMARY: {
      DYNAMIC_ROUTE: (eventId: number) => `${eventId}/event_summary`,
    },
    GET_EVENT_MASS_IMPORT_LIST: {
      DYNAMIC_ROUTE: (eventId: number) => `${eventId}/mass_imports`,
    },
    GET_EVENT_MASS_IMPORT: {
      DYNAMIC_ROUTE: (eventId: number, massImportId: number) => `${eventId}/mass_imports/${massImportId}`,
    },
    MASS_IMPORT_CHECK: {
      DYNAMIC_ROUTE: (eventId: number) => `${eventId}/mass_imports/check`,
      BODY: (data: Array<Array<EventMassImportDataTypes>>) => data,
    },
    MASS_IMPORT_CREATE: {
      DYNAMIC_ROUTE: (eventId: number) => `${eventId}/mass_imports/generate`,
      BODY: (data: EventMassImportCheckResult) => data,
    },
  },
  INTAKES: {
    BASE: 'intakes',
    GET_INTAKE_LIST: {},
    GET_INTAKE: {
      DYNAMIC_ROUTE: (id: number) => id,
    },
    ADD_INTAKE: {
      BODY: (data: IntakePostData) => data,
    },
    GET_INTAKE_SESSION_LIST: {
      DYNAMIC_ROUTE: (intakeId: number) => `${intakeId}/sessions`,
    },
    DELETE_INTAKE_SESSION: {
      DYNAMIC_ROUTE: (intakeId: number, sessionId: number) => `${intakeId}/sessions/${sessionId}`,
    },
    GET_INTAKE_SESSION: {
      DYNAMIC_ROUTE: (intakeId: number, sessionId: number) => `${intakeId}/sessions/${sessionId}`,
    },
    PATCH_INTAKE_SESSION_STATE: {
      DYNAMIC_ROUTE: (intakeId: number, actableId: number, action: IntakeSessionAction) =>
        `${intakeId}/sessions/${actableId}/${action}`,
      BODY: (data?: IntakeSessionPostData) => (data ? data : {}),
    },
    PATCH_INTAKE_SESSION: {
      DYNAMIC_ROUTE: (intakeId: number, data: IntakeSessionPostData) =>
        `${intakeId}/sessions/${data.intake_session.id}`, // id is actable_id
      BODY: (data: IntakeSessionPostData) => data,
    },
    POST_INTAKE_SESSION: {
      DYNAMIC_ROUTE: (intakeId: number) => `${intakeId}/sessions`,
      BODY: (data: IntakeSessionNewData) => data,
    },
    GET_INTAKE_ATTACHMENT_LIST: {
      DYNAMIC_ROUTE: (intakeId: number) => `${intakeId}/attachments`,
    },
    ADD_INTAKE_ATTACHMENT_LIST: {
      DYNAMIC_ROUTE: (intakeId: number) => `${intakeId}/attachments`,
      BODY: (attachmentData: IntakeAttachmentPostData) => attachmentData,
    },
    EDIT_INTAKE_ATTACHMENT_LIST: {
      DYNAMIC_ROUTE: (intakeId: number) => `${intakeId}/attachments`,
      BODY: (attachmentData: AttachmentPostData) => attachmentData,
    },
    UPDATE_INTAKE: {
      DYNAMIC_ROUTE: (data: IntakePatchData) => data.id,
      BODY: (data: IntakePatchData) => data,
    },
    POST_INTAKE_ACTION: {
      DYNAMIC_ROUTE: (id: number) => id,
      BODY: (data: IntakeActionsPostData) => data,
    },
    ADD_INTAKE_OUTCOME: {
      DYNAMIC_ROUTE: (intakeId: number) => `${intakeId}/outcomes`,
      BODY: (data: IntakeOutcomeNewData) => data,
    },
    APPROVE_INTAKE_PROMOTION: {
      DYNAMIC_ROUTE: (intakeId: number) => intakeId,
      BODY: (data: IntakePromoteDecisionPostData) => data,
    },
    MAKE_INTAKE_OUTCOME_DECISIONS_LIONS: {
      // Should not be called when not IS_LIONS
      DYNAMIC_ROUTE: (intakeId: number) => '',
      BODY: (data: IntakePromoteDecisionPostData) => '',
    },
    APPROVE_INTAKE_OUTCOME: {
      DYNAMIC_ROUTE: (intakeId: number) => intakeId,
      BODY: (data: IntakeOutcomeApprovalPostData) => data,
    },
    PATCH_INTAKE_ASSESSMENT: {
      DYNAMIC_ROUTE: (intakeId: number, data: IntakeAssessmentPostData) =>
        `${intakeId}/assessments/${data.intake_assessment.id}`,
      BODY: (data: IntakeAssessmentPostData) => data,
    },
    POST_INTAKE_ASSESSMENT: {
      DYNAMIC_ROUTE: (intakeId: number) => `${intakeId}/assessments`,
      BODY: (data: IntakeAssessmentPostData) => data,
    },
  },
  LOGS: {
    BASE: 'logs',
    GET_LOG_LIST: {
      ROUTE: 'index_v1',
    },
    GET_LOGS_EXCEL: {
      ROUTE: 'download_excel',
      BLOB: () => ({
        mimeType: 'application',
        mimeSubtype: 'xls',
      }),
    },
  },
  PUBLIC_ACTIVITIES: {
    BASE: 'public_activities',
    GET_PUBLIC_ACTIVITY_LIST: {},
    GET_PUBLIC_ACTIVITY_LIST_PREVIEW: {},
    MARK_PUBLIC_ACTIVITY_READ: {
      DYNAMIC_ROUTE: (id: number) => `${id}/mark_read`,
    },
  },
  REPORTS: {
    BASE: 'reports',
    WEEKLY_SESSION_REPORT: {
      DYNAMIC_ROUTE: (dateRange: DateRange) =>
        `weekly_session_report/from/${dateRange.start_date}/to/${dateRange.end_date}`,
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
      BODY: (centreId: number | undefined) => {
        return { centre_id: centreId };
      },
    },
    CASE_SUMMARY_REPORT: {
      DYNAMIC_ROUTE: (dateRange: DateRange) =>
        `case_summary_report/from/${dateRange.start_date}/to/${dateRange.end_date}`,
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
      BODY: (
        centreId: number | undefined,
        caseCategoryId: number | undefined,
        case_type: { case_type_id: number | undefined },
      ) => {
        return {
          centre_id: centreId,
          case_category_id: caseCategoryId,
          ...case_type,
        };
      },
    },
    NUMBER_OF_SESSIONS_REPORT: {
      DYNAMIC_ROUTE: (dateRange: DateRange) =>
        `number_of_sessions_report/from/${dateRange.start_date}/to/${dateRange.end_date}`,
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
    },
    CLOSED_CASE_REPORT: {
      DYNAMIC_ROUTE: (dateRange: DateRange) =>
        `closed_case_report/from/${dateRange.start_date}/to/${dateRange.end_date}`,
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
      BODY: (centreId: number | undefined) => {
        return { centre_id: centreId };
      },
    },
    DORMANT_CASE_REPORT: {
      DYNAMIC_ROUTE: (selectDate: string) => `dormant_case_report/date/${selectDate}`,
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
    },
    INDIVIDUAL_COUNSELLOR_REPORT: {
      DYNAMIC_ROUTE: (dateRange: DateRange) =>
        `individual_counsellor_report/from/${dateRange.start_date}/to/${dateRange.end_date}`,
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
      BODY: (
        staffId: number | undefined,
        case_type: { case_type_id: number | undefined },
        centreId: number | undefined,
      ) => {
        return { staff: staffId, ...case_type, centre_id: centreId };
      },
    },
    SUPERVISION_SESSION_REPORT: {
      DYNAMIC_ROUTE: (dateRange: DateRange) =>
        `supervision_session_report/from/${dateRange.start_date}/to/${dateRange.end_date}`,
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
      BODY: (centreId: number | undefined) => {
        return { centre_id: centreId };
      },
    },
    COUNSELLOR_WORKLOAD_REPORT: {
      DYNAMIC_ROUTE: (selectDate: string) => `counsellor_workload_report/date/${selectDate}`,
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
      BODY: (centreId: number | undefined) => {
        return { centre_id: centreId };
      },
    },
    CASE_MISSING_PRIMARY_PROBLEM_REPORT: {
      ROUTE: 'case_missing_primary_problem_report',
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
      BODY: (centreId: number | undefined) => {
        return { centre_id: centreId };
      },
    },
    CLIENT_SPECIAL_REPORT: {
      DYNAMIC_ROUTE: (dateRange: DateRange) =>
        `client_special_report/from/${dateRange.start_date}/to/${dateRange.end_date}`,
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
      BODY: (case_category: { case_category_id: number | undefined } | undefined, centreId: number | undefined) => {
        return { ...case_category, centre_id: centreId };
      },
    },
    DAILY_APPOINTMENT_REPORT: {
      DYNAMIC_ROUTE: (selectDate: string) => `daily_appointment_report/date/${selectDate}`,
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
      BODY: (centreId: number | undefined) => {
        return { centre_id: centreId };
      },
    },
    CASE_SPECIAL_REPORT: {
      DYNAMIC_ROUTE: (dateRange: DateRange) =>
        `case_special_report/from/${dateRange.start_date}/to/${dateRange.end_date}`,
      BODY: (caseCategoryId: number | undefined, centreId: number | undefined) => {
        return { case_category_id: caseCategoryId, centre_id: centreId };
      },
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
    },
    INTAKE_SPECIAL_REPORT: {
      DYNAMIC_ROUTE: (dateRange: DateRange) =>
        `intake_special_report/from/${dateRange.start_date}/to/${dateRange.end_date}`,
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
      BODY: (centreId: number | undefined) => {
        return { centre_id: centreId };
      },
    },
    INDIVIDUAL_CC_CASEWORKER_REPORT: {
      DYNAMIC_ROUTE: (dateRange: DateRange) =>
        `individual_cc_caseworker_report/from/${dateRange.start_date}/to/${dateRange.end_date}`,
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
      BODY: (staffId: number | undefined, centreId: number | undefined) => {
        return { staff_id: staffId, centre_id: centreId };
      },
    },
    INDIVIDUAL_SESV_CASEWORKER_REPORT: {
      DYNAMIC_ROUTE: (dateRange: DateRange) =>
        `individual_se_sv_caseworker_report/from/${dateRange.start_date}/to/${dateRange.end_date}`,
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
      BODY: (staffId: number | undefined, centreId: number | undefined) => {
        return { staff_id: staffId, centre_id: centreId };
      },
    },
    INDIVIDUAL_PATHS_CASEWORKER_REPORT: {
      DYNAMIC_ROUTE: (dateRange: DateRange) =>
        `individual_paths_caseworker_report/from/${dateRange.start_date}/to/${dateRange.end_date}`,
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
      BODY: (staffId: number | undefined, centreId: number | undefined) => {
        return { staff_id: staffId, centre_id: centreId };
      },
    },
    INDIVIDUAL_CCI_CASEWORKER_REPORT: {
      DYNAMIC_ROUTE: (dateRange: DateRange) =>
        `individual_cci_caseworker_report/from/${dateRange.start_date}/to/${dateRange.end_date}`,
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
      BODY: (staffId: number | undefined, centreId: number | undefined) => {
        return { staff_id: staffId, centre_id: centreId };
      },
    },
    INDIVIDUAL_PROGRAMMES_PERFORMANCE_REPORT: {
      DYNAMIC_ROUTE: (dateRange: DateRange) =>
        `individual_programmes_performance_report/from/${dateRange.start_date}/to/${dateRange.end_date}`,
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
      BODY: (staffId: number | undefined, centreId: number | undefined) => {
        return { staff_id: staffId, centre_id: centreId };
      },
    },
    CC_SUMMARY_REPORT: {
      DYNAMIC_ROUTE: (dateRange: DateRange) =>
        `cc_summary_report/from/${dateRange.start_date}/to/${dateRange.end_date}`,
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
      BODY: (centreId: number | undefined) => {
        return { centre_id: centreId };
      },
    },
    SUPERVISED_EXCHANGE_SUMMARY_REPORT: {
      DYNAMIC_ROUTE: (dateRange: DateRange) =>
        `supervised_exchange_summary_report/from/${dateRange.start_date}/to/${dateRange.end_date}`,
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
      BODY: (centreId: number | undefined) => {
        return { centre_id: centreId };
      },
    },
    SUPERVISED_VISITATION_SUMMARY_REPORT: {
      DYNAMIC_ROUTE: (dateRange: DateRange) =>
        `supervised_visitation_summary_report/from/${dateRange.start_date}/to/${dateRange.end_date}`,
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: 'xls',
        };
      },
      BODY: (centreId: number | undefined) => {
        return { centre_id: centreId };
      },
    },
    EPES_REPORT: {
      DYNAMIC_ROUTE: (dateRange: DateRange) => `epes_report/from/${dateRange.start_date}/to/${dateRange.end_date}`,
      BLOB: () => {
        return {
          mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          mimeSubtype: 'xlsx',
        };
      },
    },
    CS_SUMMARY_REPORT: {
      DYNAMIC_ROUTE: (dateRange: DateRange) =>
        `cs_summary_report/from/${dateRange.start_date}/to/${dateRange.end_date}`,
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: '',
        };
      },
    },
    CS_HOME_VISIT_MONITORING_REPORT: {
      ROUTE: 'cs_home_visit_monitoring_report',
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: '',
        };
      },
    },
    CS_CASE_LIST: {
      ROUTE: 'cs_case_list',
      BLOB: () => {
        return {
          mimeType: 'application',
          mimeSubtype: '',
        };
      },
    },
  },
  SUPERVISION: {
    BASE: 'supervision/sessions',
    GET_SUPERVISION_SESSION_LIST: {},
    GET_SUPERVISION_SESSION: {
      DYNAMIC_ROUTE: (sessionId: number) => sessionId,
    },
    ADD_SUPERVISION_SESSION: {
      BODY: (session: SupervisionSessionPostData) => session,
    },
    PATCH_SUPERVISION_SESSION: {
      DYNAMIC_ROUTE: (sessionId: number) => sessionId,
      BODY: (session: SupervisionSessionPostData) => session,
    },
    DELETE_SUPERVISION_SESSION: {
      DYNAMIC_ROUTE: (sessionId: number) => sessionId,
    },
    GET_SUPERVISION_SESSION_CASE_LIST: {
      DYNAMIC_ROUTE: (sessionId: number) => `${sessionId}/supervision_cases`,
    },
    GET_SUPERVISION_SESSION_CASE_REVIEW_LIST: {
      DYNAMIC_ROUTE: (sessionId: number) => `${sessionId}/supervision_cases/case_reviews`,
    },
    GET_SUPERVISION_SESSION_CLINICAL_LOG_LIST: {
      DYNAMIC_ROUTE: (sessionId: number) => `${sessionId}/clinical_logs`,
    },
    GET_SUPERVISION_SESSION_CASE: {
      DYNAMIC_ROUTE: (sessionId: number) => `${sessionId}/supervision_cases`,
      BODY: (caseId: number) => {
        return {
          case_creator_id: caseId,
        };
      },
    },
    GET_SUPERVISION_SESSION_CASE_REVIEW: {
      DYNAMIC_ROUTE: (sessionId: number, caseReviewId: number) => `${sessionId}/case_reviews/${caseReviewId}`,
    },
    GET_SUPERVISION_SESSION_CLINICAL_LOG: {
      DYNAMIC_ROUTE: (sessionId: number, clinicalLogId: number) => `${sessionId}/clinical_logs/${clinicalLogId}`,
    },
    DELETE_SUPERVISION_SESSION_CASE_REVIEW: {
      DYNAMIC_ROUTE: (sessionId: number, caseReviewId: number) => `${sessionId}/case_reviews/${caseReviewId}`,
    },
    GET_SUPERVISION_SESSION_CASE_REVIEW_INITIAL_DATA: {
      DYNAMIC_ROUTE: (sessionId: number) => `${sessionId}/case_reviews/new`,
      BODY: (caseId: number) => {
        return {
          case_creator_id: caseId,
        };
      },
    },
    POST_SUPERVISION_SESSION_CASE_REVIEW: {
      DYNAMIC_ROUTE: (sessionId: number) => `${sessionId}/case_reviews`,
      BODY: (data: SupervisionSessionCaseReviewNewData) => data,
    },
    PATCH_SUPERVISION_SESSION_CASE_REVIEW: {
      DYNAMIC_ROUTE: (sessionId: number, caseReviewId: number, action?: SupervisionSessionCaseReviewAction) =>
        `${sessionId}/case_reviews/${caseReviewId + (action ? '/' + action : '')}`,
      BODY: (data?: SupervisionSessionCaseReviewPostData) => {
        return data ? data : {};
      },
    },
    DELETE_SUPERVISION_SESSION_CLINICAL_LOG: {
      DYNAMIC_ROUTE: (sessionId: number, clinicalLogId: number) => `${sessionId}/clinical_logs/${clinicalLogId}`,
    },
    GET_SUPERVISION_SESSION_CLINICAL_LOG_INITIAL_DATA: {
      DYNAMIC_ROUTE: (sessionId: number) => `${sessionId}/clinical_logs/new`,
      BODY: (caseId: number) => {
        return {
          case_creator_id: caseId,
        };
      },
    },
    POST_SUPERVISION_SESSION_CLINICAL_LOG: {
      DYNAMIC_ROUTE: (sessionId: number) => `${sessionId}/clinical_logs`,
      BODY: (data: SupervisionSessionClinicalLogNewData) => data,
    },
    PATCH_SUPERVISION_SESSION_CLINICAL_LOG: {
      DYNAMIC_ROUTE: (sessionId: number, clinicalLogId: number) => `${sessionId}/clinical_logs/${clinicalLogId}`,
      BODY: (data: SupervisionSessionClinicalLogPostData) => data,
    },
  },
  TASKS: {
    BASE: 'tasks',
  },
};
