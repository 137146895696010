import { ChangePasswordPostData } from 'types/auth';
import { IntakePromoteDecisionPostData } from 'types/intakes';

export default {
  AUTH: {
    BASE: 'users',
    SIGN_IN: {
      ROUTE: 'sign_in',
      BODY: (login: string, password: string, rememberMe: boolean) => {
        return {
          user: {
            login: login,
            password: password,
            remember_me: rememberMe,
          },
        };
      },
    },
    TWO_FACTOR: {
      BASE: 'two_factor',
      // TOTP
      GET_TOTP: {
        ROUTE: 'totp_requests',
      },
      SUBMIT_TOTP: {
        ROUTE: 'totp_requests',
        BODY: (otpAttempt: string) => {
          return {
            two_factor_otp_request: {
              otp: otpAttempt,
            },
          };
        },
      },
      GENERATE_TOTP: {
        ROUTE: 'totp_requests/generate',
      },
      // EMAIL OTP
      SEND_OTP: {
        ROUTE: 'email_requests/send',
      },
      RESEND_OTP: {
        ROUTE: 'email_requests/resend',
      },
      SUBMIT_OTP: {
        ROUTE: 'email_requests',
        BODY: (otpAttempt: string) => {
          return {
            two_factor_otp_request: {
              otp: otpAttempt,
            },
          };
        },
      },
    },
    SGPASS: {
      BASE: 'sgpass',
      INIT_SGPASS_AUTH: {
        ROUTE: 'create',
      },
      GET_SGPASS_AUTH_STATUS: {
        ROUTE: 'status',
        BODY: (code: string | null, state: string | null, redirectUri: string) => {
          return {
            code,
            state,
            redirect_uri: redirectUri,
          };
        },
      },
    },
    SIGN_OUT: {
      ROUTE: 'sign_out',
    },
    FORGET_PASSWORD: {
      ROUTE: 'password',
      BODY: (email: string, redirectUrl: string) => {
        return {
          user: {
            email: email,
            redirect_url: redirectUrl,
          },
        };
      },
    },
    RESET_PASSWORD: {
      ROUTE: 'password',
      BODY: (token: string, password: string, passwordConfirmation: string) => {
        return {
          user: {
            reset_password_token: token,
            password: password,
            password_confirmation: passwordConfirmation,
          },
        };
      },
    },
    GET_CHANGE_PASSWORD_TOKEN: {
      ROUTE: 'edit',
    },
    CHANGE_PASSWORD: {
      DYNAMIC_ROUTE: (userId: number) => userId,
      BODY: (formValues: ChangePasswordPostData) => {
        return {
          user: formValues,
        };
      },
    },
    MASQUERADE_AS: {},
    GET_CURRENT_USER: {
      ROUTE: 'current_user',
    },
    UNMASQUERADE: {},
  },
  INTAKES: {
    MAKE_INTAKE_OUTCOME_DECISIONS_LIONS: {
      DYNAMIC_ROUTE: (intakeId: number) => `${intakeId}/intake_outcomes`,
      BODY: (data: IntakePromoteDecisionPostData) => data,
    },
  },
};
