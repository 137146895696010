import { Options } from 'utilities/options';

export enum FieldTypeEnum {
  Freetext = 'freetext',
  MCQ = 'mcq',
  MRQ = 'mrq',
  GROUP_MRQ = 'groupmrq',
}

export interface DisplayQuestions {
  [key: number]: Array<number>;
}

export interface FieldDetails {
  name: string;
  label: string;
  // freetext = RTE, mcq = multiple options and 1 choice, mrq = multiple options and multiple choices
  type: FieldTypeEnum;
  xs: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  innerxs: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  index?: number;
  dependent?: number;
  condition?: number[];
  options?: Options;
  required?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  defaultOption?: number;
  defaultOptions?: number[];
  group?: {
    name: string;
    options: (number | string)[];
  }[];
}

export interface Forms {
  ADMIN: {
    STAFF: {
      EDIT: {
        COMPONENTS: { [key: string]: string[] };
        ENTRIES: string[];
      };
      LOGIN: {
        COMPONENTS: { [key: string]: string[] };
        ENTRIES: string[];
      };
    };
  };
  CASE: {
    CASE_TRANSFER: {
      fields: FieldDetails[];
    };
    CASE_CLOSURE: {
      fields: FieldDetails[];
      display: DisplayQuestions;
    };
    CASE_SUSPENSION: {
      fields: FieldDetails[];
    };
    CASE_REOPEN: {
      fields: FieldDetails[];
    };
    FEEDBACK: {
      fields: FieldDetails[];
    };
    CASE_SESSION: {
      COMPONENTS: { [key: string]: string[] };
      ENTRIES: string[];
    };
    DETAILS: {
      COMPONENTS: { [key: string]: string[] };
      ENTRIES: string[];
    };
    NEW_CASE_SESSION: {
      COMPONENTS: { [key: string]: string[] };
      ENTRIES: string[];
    };
    NEW_TEMPORAL_PERMISSION: {
      COMPONENTS: { [key: string]: string[] };
      ENTRIES: string[];
    };
  };
  INTAKE: {
    DETAILS: {
      REFERRAL: {
        COMPONENTS: { [key: string]: string[] };
        ENTRIES: string[];
      };
      INFO: {
        COMPONENTS: { [key: string]: string[] };
        ENTRIES: string[];
      };
    };
    DETAILS_OVERVIEW: {
      PROMOTE_CASE: {
        COMPONENTS: { [key: string]: string[] };
        ENTRIES: string[];
      };
    };
    NEW_INTAKE: {
      COMPONENTS: { [key: string]: string[] };
      ENTRIES: string[];
    };
  };
  STAFF: {
    NEW: {
      COMPONENTS: { [key: string]: string[] };
      ENTRIES: string[];
    };
  };
  SUPERVISION: {
    CASE_REVIEW: {
      COMPONENTS: { [key: string]: string[] };
      ENTRIES: string[];
    };
    SESSION: {
      COMPONENTS: { [key: string]: string[] };
      ENTRIES: string[];
    };
    CASE_REVIEW_DETAILS: {
      COMPONENTS: { [key: string]: string[] };
      ENTRIES: string[];
    };
  };
}
