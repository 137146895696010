export default {
  TABLE: {
    ATTACHMENT: {
      COLUMN: ['date', 'fileName', 'cvwoCreatedBy', 'actions'],
    },
    CASE: {
      INITIAL_SORT_KEY: 'case_cases.start_date',
      INITIAL_SORT_ORDER: 'desc',
      COLUMN: [
        'caseNo',
        'caseRefId',
        'mainClient',
        'ljfsoIcNumber',
        'mainCounsellor',
        'dateOpened',
        'dateClosed',
        'caseType',
        'keyAssessedProblem',
      ],
      FILTERS: [
        'counsellors',
        'keyAssessedProblems',
        'caseTypes',
        'caseStates',
        'caseRiskStatus',
        'caseActivityStatus',
      ],
      TEMPORAL_PERMISSIONS: {
        TITLE: 'Temporal Permissions',
        DELETE_MESSAGE_LABEL: 'Temporal Permission',
        COLUMN: ['name', 'permissionRole', 'startDate', 'endDate', 'actions'],
        DATA: ['permissionStaffName', 'perimssionRole', 'startDate', 'endDate', 'actions'],
      },
    },
    CASE_ACTIVITY: {
      COLUMN: ['date', 'name', 'status', 'duration', 'createdBy', 'actions'],
    },
    CASE_HISTORY: {
      COLUMN: ['caseNo', 'displayId', 'startDate', 'closeDate']
    },
    CLIENT: {
      COLUMN: ['name', 'ic', 'contact'],
    },
    STAFF: {
      COLUMN: ['name', 'username', 'roles', 'title', 'status'],
    },
    TASK: {
      COLUMN: ['item', 'status', 'date', 'actions'],
    },
    NEXT_OF_KIN: {
      COLUMN: ['primary', 'involved', 'relationship', 'customName'],
    },
    REFERRALS: {
      TITLE: 'Referral Sources',
      COLUMN: [
        'referralDate',
        'type',
        'personSortable',
        'organisationSortable',
        'contacts',
        'address',
        'relationship',
        'remarksSortable',
      ],
    },
    INTAKE: {
      INITIAL_SORT_KEY: 'intake_intakes.start_date',
      INITIAL_SORT_ORDER: 'desc',
      COLUMN: [
        'lfbclName',
        'zone',
        'lfbclIdentity',
        'assignedCaseWorker',
        'referralDate',
        'assessmentDate',
        'csIntakeStatus',
        'actions',
      ],
      DATA: [
        'clientName',
        'centreName',
        'identityDocument',
        'staffName',
        'intakeDate',
        'assessmentDate',
        'intakeStatus',
        'showButton',
      ],
      FILTERS: ['assignedCaseWorker', 'conditionalIntakeStatus'],
    },
    OUTCOME_ACTIVITIES: {
      COLUMN: ['date', 'finalOutcome', 'finalOutcomeRemarks', 'decision', 'decisionRemarks'],
    },
    SUPERVISION: {
      COLUMN: ['startDateAndTime', 'supervisors', 'supervisees', 'actions'],
    },
    CLIENT_SPECIAL_REPORT: {
      CLIENTS_TABLE_COLUMN: [
        'clientName',
        'caseNumber',
        'caseReference',
        'counsellor',
        'identityDocumentation',
        'riskStatus',
        'age',
        'maritalStatus',
        'gender',
        'individualIncome',
        'occupationStatus',
        'race',
        'religion',
        'educationLevel',
        'languages',
        'lastModifed',
        'modifiedBy',
      ],
      STATISTICS: [
        'riskStatus',
        'ageGroup',
        'maritalStatus',
        'gender',
        'individualIncome',
        'occupationStatus',
        'race',
        'religion',
        'educationLevel',
        'language',
      ],
    },
    CASE_SPECIAL_REPORT: {
      CASES_TABLE_COLUMN: [
        'id',
        'caseNumber',
        'caseRef',
        'caseStartDate',
        'primaryProblem',
        'secondaryProblems',
        'counsellingFeeAmount',
      ],
      STATISTICS: ['counsellingFeesByCases', 'assessedProblems'],
    },
  },
};
